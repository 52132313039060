import React, { Component } from "react";
import shop from "../../images/slider/img1.jpg";

export class Main extends Component {
  render() {
    return (
      <div id="overOns" className="">
        <section class="flex items-center bg-white py-2 md:py-10 font-poppins xl:pt-24 xl:pb-16  ">
          <div class="justify-center flex-1 max-w-8xl py-4 mx-auto lg:py-6 md:px-6">
            <div class="flex flex-wrap xl:w-3/4 xl:m-auto">
              <div class="w-full lg:px-12 mb-10 lg:w-2/4 lg:mb-0">
                <div class="relative">
                  <img src={shop} alt=""
                    class="relative z-40 object-cover w-full h-96 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded" />
                  <div
                    class="absolute z-10 hidden w-full h-full bg-yellow-500 rounded-bl-[80px] rounded -bottom-6 right-6 lg:block">
                  </div>
                </div>
              </div>
              <div class="w-full mt-4 mb-8 lg:px-12 md:mb-10 lg:w-1/2 lg:mb-0 xl:mt-6 ">
                <div class="relative">
                  <h1
                    class="absolute -top-10 xl:  left-0 text-[20px] lg:text-[50px] text-gray-900 font-bold  dark:text-gray-200 opacity-5 md:block hidden" style={{ fontFamily: "Oswald", textShadow: "2px 2px 4px #ffffff" }} >
                    UW WARME BAKKER
                  </h1>
                  <h1 class="pl-2 text-6xl font-bold md:border-l-8 border-yellow-500 md:text-6xl dark:text-white text-center md:text-left" style={{ fontFamily: "Oswald", textShadow: "2px 2px 4px #ffffff" }}>
                    OVER ONS
                  </h1>
                </div>
                <p class="mt-12 md:mt-8 mb-2 md:mb-10 text-base text-black font-medium tracking-wide leading-9 text-center mx-6 md:text-left md:mx-0 " style={{ fontFamily: "Oswald", fontSize: "22px" }}>
                  Bakkerij Guven in Berchem biedt een gevarieerde selectie van vers brood, patisserie, Turkse specialiteiten, broodjes, en zelfs pizza's tegen betaalbare prijzen.
                  Wij staan bekend om onze dagelijkse versgebakken broden, en je kunt bij ons ook in alle rust genieten van je maaltijd dankzij beschikbare zitplaatsen.
                  Wij hebben een zoet assortiment met baklava, tiramisu, taarten en muffins, en bieden zelfs gepersonaliseerde taarten voor speciale gelegenheden.
                  Kortom, Bakkerij Guven is een veelzijdige en toegankelijke plek voor heerlijke lekkernijen in het centrum van Berchem.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="">
       </div>
        <section class="flex items-center bg-yellow-500 py-2 md:py-10 font-poppins xl:pt-24 xl:pb-16  ">
          <div class="justify-center flex-1 max-w-8xl py-4 mx-auto lg:py-6 md:px-6">
            <div class="flex flex-wrap xl:w-3/4 xl:m-auto">
              <div class="w-full mt-4 mb-8 lg:px-12 md:mb-10 lg:w-1/2 lg:mb-0 xl:mt-6 ">
                <div class="relative">
                  <h1
                    class="absolute -top-10 xl:  left-0 text-[20px] lg:text-[50px] text-gray-900 font-bold  dark:text-gray-200 opacity-5 md:block hidden" style={{ fontFamily: "Oswald", textShadow: "2px 2px 4px #ffffff" }} >
                    UW WARME BAKKER
                  </h1>
                  <h1 class="pl-2 text-6xl font-bold md:border-l-8 border-black md:text-6xl dark:text-white text-center md:text-left" style={{ fontFamily: "Oswald", textShadow: "2px 2px 4px #ffffff" }}>
                    WAAR IS GUVEN BAKKERIJ?
                  </h1>
                </div>
                <p class="mt-12 md:mt-8 mb-2 md:mb-10 text-base text-black font-medium tracking-wide leading-9 text-center mx-6 md:text-left md:mx-0 " style={{ fontFamily: "Oswald", fontSize: "22px" }}>
                  "Bakkerij Guven is gunstig gelegen in het hart van Berchem en gemakkelijk bereikbaar via openbaar vervoer en de auto. Buslijnen 32 en 23 stoppen op slechts 20 meter afstand, het treinstation van Berchem ligt op 600 meter afstand en tramlijnen 15 en 7 stoppen bij Berchem station. Voor auto's is er parkeergelegenheid op de ruime parkeerplaats in de Villegasstraat, op slechts 100 meter van onze bakkerij."
                </p>
              </div>
              <div class="w-full lg:px-12 mb-10 lg:w-2/4 lg:mb-0">
                <div class="relative">
                  <div className="border-2 border-black w-full h-full p-2">
                    <iframe
                    title="Google Maps Location"
                      className="relative z-40 object-cover w-full h-96  rounded"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2500.203393055799!2d4.420627815758578!3d51.19690377958538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3f7e70a6ee913%3A0x2928fd8ae4906239!2sGuven%20Bakkerij!5e0!3m2!1snl!2sbe!4v1640811246599!5m2!1snl!2sbe"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
