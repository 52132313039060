import React, { useState } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/solid';
import logo from '../../images/logo.png'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div id='home' className='bg-opacity-80 bg-black py-2'>
            {/* Centered Image above the navbar for larger screens */}
            <div className="hidden md:flex justify-center">
                <img src={logo} alt="Header Logo" className="w-32 md:w-48" />
            </div>
            <nav className="p-6 relative">
                <div className="container mx-auto">
                    <div className="flex justify-between items-center">
                        {/* Navigation Links */}
                        <div className={`flex justify-center w-full ${isOpen ? 'flex-col mt-14' : 'hidden md:flex'}`}>
                            <a className="text-white text-2xl md:px-2 mb-2 md:mb-0 md:mr-4 hover:text-yellow-500 transition duration-300 ease-in-out animate-underline" style={{ fontFamily: 'Bubbler One' }} href="#home">Home</a>
                            <a className="text-white text-2xl md:px-2 mb-2 md:mb-0 md:mr-4 hover:text-yellow-500 transition duration-300 ease-in-out animate-underline" style={{ fontFamily: 'Bubbler One' }} href="#overOns">Over Ons</a>
                            <a className="text-white text-2xl md:px-2 mb-2 md:mb-0 md:mr-4 hover:text-yellow-500 transition duration-300 ease-in-out animate-underline" style={{ fontFamily: 'Bubbler One' }} href="#bestellen">Bestellen</a>
                            <a className="text-white text-2xl md:px-2 mb-2 md:mb-0 hover:text-yellow-500 transition duration-300 ease-in-out animate-underline" style={{ fontFamily: 'Bubbler One' }} href="#contact">Contact</a>
                        </div>
                        {/* Hamburger and Close Icons for smaller screens */}
                        {isOpen ? (
                            <XIcon className="absolute top-4 left-4 h-6 w-6 text-white hover:text-gray-200 md:hidden" onClick={() => setIsOpen(false)} />
                        ) : (
                            <MenuIcon className="h-6 w-6 text-white hover:text-gray-200 md:hidden" onClick={() => setIsOpen(true)} />
                        )}
                        <div className="md:hidden md:flex justify-center absolute top-3 right-4">
                            <img src={logo} alt="Header Logo" className="w-16" />
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
