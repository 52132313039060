import "./App.css";
import HomePage from "./pages/homePage/homePage";
import React, { Component }  from 'react';

function App() {

  return (
    <div >
      <HomePage />
    </div>
  );
}

export default App;
