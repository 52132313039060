import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
      emailjs.sendForm('service_v6pzw5a', 'template_yzpjojz', form.current, 'y7xrV0q9EYEQ83Fjd')
        .then((result) => {
            console.log(result.text);
            console.log('send')
        }, (error) => {
            console.log(error.text);
            console.log('error')
        });
    };

    return (
        <div id='contact' className="bg-contact">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 text-center w-7/8 lg:w-2/4">
                <h2 className="text-4xl xl:text-6xl font-bold text-white">Heeft u een vraag?</h2>
                <p className="pt-6 pb-6 text-base text-xl lg:text-2xl max-w-2xl text-center m-auto dark:text-neutral-400 text-white">
                    Wenst u een taart te bestellen voor uw speciale gelegenheid? Of heeft u een vraag?<br /> Aarzel niet om contact met ons op te nemen!
                </p>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 pb-16 grid md:grid-cols-1 lg:grid-cols-1 gap-y-8 md:gap-x-8 md:gap-y-8 lg:gap-x-8 lg:gap-y-16 w-7/8 lg:w-2/4">
                <div>
                    <form ref={form} onSubmit={sendEmail}>
                        <input type="checkbox" id="" class="hidden" style={{
                            display: 'none'
                        }} name="botcheck" />
                        <div class="mb-5">
                            <input type="text" placeholder="Naam" autocomplete="false "
                                class="w-full px-4 py-3 border-2 placeholder:text-neutral-800 dark:text-white rounded-md outline-none dark:placeholder:text-neutral-200 dark:bg-neutral-900 focus:ring-4 border-neutral-300 focus:border-neutral-600 ring-neutral-100 dark:border-neutral-600 dark:focus:border-white dark:ring-0"
                                name="from_name"  />
                        </div>
                        <div class="mb-5">
                            <label for="email_address" class="sr-only">Email Address</label>
                            <input id="email_address" type="email" placeholder="Email Address" autocomplete="false"
                                class="w-full px-4 py-3 border-2 placeholder:text-neutral-800 dark:text-white rounded-md outline-none dark:placeholder:text-neutral-200 dark:bg-neutral-900   focus:ring-4  border-neutral-300 focus:border-neutral-600 ring-neutral-100 dark:border-neutral-600 dark:focus:border-white dark:ring-0"
                                name="from_email"  />
                        </div>
                        <div class="mb-3">
                            <textarea placeholder="Uw Bericht"
                                class="w-full px-4 py-3 border-2 placeholder:text-neutral-800 dark:text-white dark:placeholder:text-neutral-200 dark:bg-neutral-900   rounded-md outline-none  h-36 focus:ring-4  border-neutral-300 focus:border-neutral-600 ring-neutral-100 dark:border-neutral-600 dark:focus:border-white dark:ring-0"
                                name="message" >
                            </textarea>
                        </div>
                        <input type="submit" value="Send" placeholder='Verstuur Bericht'
                            class="w-full py-4 font-semibold text-white transition-colors bg-yellow-500 rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-offset-2 focus:ring focus:ring-neutral-200 px-7 dark:bg-white dark:text-black ">
                            
                        </input>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
