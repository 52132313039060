import React from "react";

const Footer = () => {
  let currentYear = new Date().getFullYear();
  return (
    <div id="footerID" className="bg-black w-full h-full">
      <div className="grid grid-cols-1 xl:grid-cols-2 w-full xl:w-4/6 m-auto">
        <div className="my-12 m-auto">
          <p
            className="font-normal text-5xl  xl:text-2xl text-white uppercase pb-12 text-center xl:text-left"
            style={{
              fontFamily: "Oswald",
            }}
          >
            contact
          </p>
          <div className="flex mb-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-20 h-20 text-black bg-yellow-500 rounded-full p-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <p
              style={{
                fontFamily: "Oswald",
              }}
              className="text-white px-4 text-lg pt-2 "
            >
              <a
                href="https://goo.gl/maps/8j7CBvFn7zPczWJRA"
                rel="noreferrer"
                className="hover:text-gray-400 w-full"
                target="_blank"
                style={{
                  fontFamily: "Oswald",
                }}
              >
                Driekoningenstraat 79 2600 Antwerpen
              </a>
            </p>
          </div>

          <div className="flex mb-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-20 h-20 text-black bg-yellow-500 rounded-full p-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <p
              style={{
                fontFamily: "Oswald",
              }}
              className="text-white px-4 text-lg pt-2 "
            >
              <a
                href="mailto:selimcan.kaygun@gmail.com"
                className="hover:text-gray-400 w-full"
                style={{
                  fontFamily: "Oswald",
                }}
              >
                guvenbakkerij@gmail.com
              </a>
            </p>
          </div>
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-20 h-20 text-black bg-yellow-500 rounded-full p-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
            <p
              style={{
                fontFamily: "Oswald",
              }}
              className="text-white px-4 text-lg pt-2 "
            >
              <a className="hover:text-gray-300" href="tel:+32485524642">
                +32 485 52 46 42
              </a>
            </p>
          </div>
        </div>
        <div className="my-12 m-auto">
          <p
            className="font-normal text-5xl  xl:text-2xl text-white uppercase pb-12 text-center xl:text-left"
            style={{
              fontFamily: "Oswald",
            }}
          >
            openingsuren
          </p>
          <div className="w-full h-full grid grid-cols-2">
            <div className="pr-4 grid  grid-cols-1 xl:grid-cols-2">
              <div className="text-left uppercase text-white">
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  Maandag:
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  Dinsdag:
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  Woensdag:
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  Donderdag:
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  Vrijdag:
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  Zaterdag:
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  Zondag:
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2">
              <div className="text-right text-white">
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  GESLOTEN
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  06:00 - 20:00
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  06:00 - 20:00{" "}
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  06:00 - 20:00
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  06:00 - 20:00
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  06:00 - 20:00
                </p>
                <p
                  className="font-normal text-lg pb-2"
                  style={{
                    fontFamily: "Oswald",
                  }}
                >
                  06:00 - 20:00
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full  xl:w-4/6 m-auto h-full pb-6"
        style={{ borderTop: "1px solid gray", borderRightColor: "red" }}
      >
        <div className="grid grid-cols-4 m-auto w-4/6">
          <p
            style={{
              fontFamily: "Oswald",
            }}
            className="text-white px-1 xl:px-2  text-lg pt-6 text-center"
          >
            <a className="hover:text-gray-300" href="#home">
              Home
            </a>
          </p>
          <p
            style={{
              fontFamily: "Oswald",
            }}
            className="text-white px-1 xl:px-2 text-lg pt-6 text-center"
          >
            <a className="hover:text-gray-300" href="#overOns">
              Over Ons
            </a>
          </p>
          <p
            style={{
              fontFamily: "Oswald",
            }}
            className="text-white px-1 xl:px-2  text-lg pt-6 text-center"
          >
            <a className="hover:text-gray-300" href="#contact">
              Contact
            </a>
          </p>
          <p
            style={{
              fontFamily: "Oswald",
            }}
            className="text-white px-1 xl:px-2  text-lg pt-6 text-center"
          >
            <a className="hover:text-gray-300" href="#bestellen">
              Bestellen?
            </a>
          </p>
        </div>
      </div>
      <div>
        <p
          style={{
            fontFamily: "Oswald",
          }}
          className="text-white px-4 text-md py-4 text-center"
        >
          Copyright &copy; {currentYear} Guven Bakkerij <br/>
          <p
          style={{
            fontFamily: "Oswald",
          }}
          className="text-white px-4 text-md py-2 text-center text-xs"
        >
          Gemaakt door{" "}
          
          <a
            target="_blank"
            className="text-red-500 hover:text-red-300 hover:underline text-xs"
            rel="noreferrer"
            href="https://selimcankaygun.netlify.app/"
          >
            Selim Kaygun
          </a>
          </p>
        </p>
      </div>
    </div>
  );
};

export default Footer;
