import React from "react";
import Navigation from "../../components/nav/navigation"
import { HomeTopText } from "../../components/homeTopText/homeTopText";
import { Main } from "../../components/main/main";
import ImageSlider from "../../components/mainImageSlider/mainImageSlide";
import Footer from "../../components/footer/footer";
import Contact from "../../components/contact/contact";

const importAll = (r) => {
  return r.keys().map(r);
};

const imageFiles = importAll(require.context('../../images/RESIZED', false, /\.(png|jpe?g|svg)$/));

const homePage = () => {
  return (
    <div className="w-full h-full " >
      <div className="homeImg">
        <Navigation />
        <HomeTopText />
        <ImageSlider imagePaths={imageFiles}  />
      </div>
      <div>
        <Main />
        <script src="https://widget.trustmary.com/bTfyoXTxY"></script>
        <Contact/>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default homePage;
