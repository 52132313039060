import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function ImageSlide({ imagePaths }) {
  var settings = {
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div id='bestellen' className='bg-black py-12'>
      <div className='h-full text-white mx-8'>
        <Slider {...settings}>
          {imagePaths.map((path, index) => (
            <div className='p-2'>
              <img key={index} src={path} alt={`Image ${index}`} style={{ height: '400px', width: '600px' }} />
            </div>
          ))}
        </Slider>
      </div>
      <a href='https://deliveroo.be/nl-be/menu/Antwerp/berchem-station/guven-bakkerij/?day=today&geohash=u155hxugkxsj&time=ASAP' target="_blank" rel="noreferrer">
        <div className='w-full flex flex-col justify-center items-center mt-12 mb-4'>
          <button className='bestelNu rounded-lg'>
            <span className='text-2xl text-white' style={{ fontFamily: 'Poppins' }}>BESTEL NU!</span>
          </button>
        </div>
      </a>
    </div>
  );
}

export default ImageSlide;
